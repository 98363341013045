@import "_reset";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import "material-symbols";

:root {
  font-size: 16px;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #000;
  background-image: url("../images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

img {
  max-width: 100%;
}

.col {
  display: flex;
  flex-direction: column;

  .full {
    flex-grow: 1;
  }
}
.col-12 {
  width: 100%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.666%;
}
.col-7 {
  width: 60%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 40%;
}
.col-4 {
  width: 33.333%;
}
.col-3 {
  width: 25%;
}

.d-flex {
  display: flex !important;

  &.flex-column {
    flex-direction: column;
  }
  &.flex-column-reverse {
    flex-direction: column-reverse;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-row-reverse {
    flex-direction: row-reverse;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.align-stretch {
    align-items: stretch;
  }
}

.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 0 !important;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-center {
  align-self: center;
}
.align-self-end {
  align-self: flex-end;
}

.justify-self-start {
  justify-self: flex-start;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-end {
  justify-self: flex-end;
}

.p-relative {
  position: relative !important;
}
