// `XHTML, HTML4, HTML5 Reset

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
input,
kbd,
label,
legend,
li,
main,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
rp,
rt,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video,
xmp {
	border: 0;
	margin: 0;
	padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
	display: block;
}

b,
strong {
	font-weight: 500;
}

img {
	color: transparent;
	vertical-align: middle;
	-ms-interpolation-mode: bicubic;
}

ol,
ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

th,
td,
caption {
	font-weight: normal;
	vertical-align: top;
	text-align: left;
}

q {
	quotes: none;
}

q:before,
q:after {
	content: none;
}

sub,
sup,
small {
	font-size: 75%;
}

sub,
sup {
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

svg {
	overflow: hidden;
}

a {
	text-decoration: none; color: inherit;
}

i { color: inherit; font: inherit; }

textarea, input, button { border: none; outline: none; font: inherit; background: none; color: inherit; }
textarea { resize: none; }

* { box-sizing: border-box; }